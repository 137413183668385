<template>
<div>
	<el-dialog title="上传本地数据" :visible.sync='dialogImportVisible'
	 width="51%" style="min-height: 200px;" 
	:append-to-body="true">
	<div class="" style="font-weight: bold; color:#F56C6C;font-size: 12px;">
	1、物料编码、物料名称至少填入一项<br />
	2、数量、单价、总价全部填写自动进行校验，填写其中两项自动计算第三项<br />
	3、生产日期、保质期、最后有效期全部填写自动进行校验，填写其中两项自动计算第三项</div>
	<div class="import-box">
		<div>1、下载模板： <a style="color: #409EFF;" @click="download">耗材导入模板</a></span></div>
		<div style="display: flex;">
			2、选择需要导入的数据文件（xls、xlsx 格式）
			<!-- <el-upload
			  class="upload-demo"
			  :data="uploadParams"
			  ref="uploadCseFile"
			  :action="uploadUrl"
			  :multiple="false"
			  :on-change="handleChange"
			  :on-success="handleImportSuccess"
			  :auto-upload="true"
			> -->
			<el-upload
			  class="upload-demo"
			  ref="uploadCseFile"
			  :action="uploadUrl"
			  :multiple="false"
			  :on-change="handleChange"
			  :on-success="handleImportSuccess"
			  :auto-upload="true"
			>
			<el-button type="primary" size="small" plain class="browse-btn">浏览</el-button> 
			</el-upload>
		</div>
	</div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogImportVisible:false,
			uploadUrl:'/api/admin/CseStorage/import',
			roleId:localStorage.getItem('role_id'),     //当前登录角色
			tempFileList:[],
			importTempUrl:'',
			fileList:[],
        }
    },
    created() {
			
    },
    mounted() {

    },
    methods:{
		handleChange(file,fileList){
			this.$emit('handleBeforeUploadFile',file,fileList,true,res=>{
				this.fileList =  res
			})
		},
		
		download(){
			location.href = '/api/admin/CseStorage/downTemplate'
		},
		
		handleImportSuccess(res){
			this.$refs.uploadCseFile.clearFiles()
		    if(res.code == 200){
				this.$message.success('Success')
				this.tableData =  res.data.table_list
				this.$parent.$parent.updateImportTableData(res.data.table_list)
				this.dialogImportVisible = false
		     }else{
		         this.$message.error(res.msg)
		     }
		},
		
    },
}
</script>
<style scoped lang="scss">
	.import-box{
		div{
			height: 45px;
			line-height: 45px;
			// display: flex;
			.browse-btn{
				width: 120px;
			}
		}
	}
	
</style>