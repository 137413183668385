<template>
<div>
	<el-dialog title="选择耗材" :visible.sync='dialogAssetVisible' width="50%" :close-on-click-modal="false"  :modal-append-to-body="false" :append-to-body="true">
		<el-table 
			border 
			ref="multipleTable"
			@selection-change="handleSelectionChange" 
			:header-cell-style="{ height:'35px',padding: '0',fontSize:'12px'}"
			:row-style="{height:'40px'}"
			 :cell-style="{padding: '0'}"
			:row-key="getKey"
			:data="tableData" 
			style="width:100%">
			<el-table-column
			     type="selection"
				:reserve-selection="true"
			     width="55">
			   </el-table-column>
			   <el-table-column
			       prop="mtl_code"
				     width="120"
			       label="物料编码">
			   </el-table-column>
			   <el-table-column
					v-if="form.type !== 1"
			       prop="location_name"
					width="120"
			       label="仓库">
			   </el-table-column>
			    <el-table-column
			       prop="name"
				    width="120"
			       label="物料名称">
			   </el-table-column>
			   <el-table-column 
					width="120"
			       prop="cate_name"
			       label="物料分类">
			   </el-table-column>
			   <el-table-column
					prop="unit_price"
				    width="120"
			       label="单价">
			   </el-table-column>
			   <el-table-column
				v-if="form.type !== 1"
			   	 prop="stock"
				width="120" 
			   	 label="可用库存">
			   </el-table-column>
			 <!--  <el-table-column
			       prop="bar_code"
			       label="商品条码">
			   </el-table-column> -->
			   <el-table-column
			       prop="barand"
				   	width="120"
			       label="品牌">
			   </el-table-column>
			   <el-table-column
			       prop="model"
				   width="120"
			       label="规格型号">
			   </el-table-column>
			   <el-table-column
			       prop="unit"
				   width="120"
			       label="计量单位">
			   </el-table-column>
			 <!--  <el-table-column
			       prop="safe_stock_clg"
			       label="安全库存上限">
			   </el-table-column>
			   <el-table-column
			       prop="safe_stock_limit"
			       label="安全库存下限">
			   </el-table-column>
			   <el-table-column
			       prop="return_status"
			       label="允许退库">
			   	 <template slot-scope="scope">
			   		 <span v-if="scope.row.return_status == 1">是</span>
			   		 <span v-if="scope.row.return_status == 2">否</span>
			   	 </template>
			   </el-table-column> -->
			  
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="subTable()">确 定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
			dialogAssetVisible:false,	
			tableData:[],
			theadData:[],
			form: {
				name: '',
				type:1,
				mtl_code_data:[]
			},
			currentPage: 1,
			pagesize: 10,
			total: 0,
			checkedData:[],
			rows:[]
        }
    },
    created() {
		
    },
    mounted() {

    },
    methods:{
		showAsset(rows=[],location_id){
			this.rows = rows
			this.form['page'] = this.currentPage-1
			this.form['limit'] = this.pagesize
			this.form['location_id'] = location_id

			this.$api.post('CseStock/index',this.form, res => {
				if (res.code == 200) {
					this.tableData = res.data.list ?? []
					this.total = res.data.count
					this.dialogAssetVisible  = true
					this.$nextTick(()=>{ 
						if(rows){
							this.$refs.multipleTable.clearSelection();
							rows.forEach(row => {
								this.$refs.multipleTable.toggleRowSelection(this.tableData.find(item=>{
									return row.id == item.id
								}),true);
							});
						}else{
							this.$refs.multipleTable.clearSelection();
						}
					})
				}
			})
		},
		getKey(row){
			return row.id
		},
		subTable(){
			this.$emit('update:tableData',this.checkedData)
			this.dialogAssetVisible = false
		},
		handleSelectionChange(val){
			this.checkedData =  val
			// console.log('check:',this.checkedData )
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.showAsset()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		    this.showAsset()
		},
		
		handleUpdateTableData(rows,location_id){
			// this.showAsset(rows,location_id)
		}
	
    },
}
</script>
<style scoped>

</style>