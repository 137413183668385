<template>
<div>
	<el-dialog :title="dialogTitle" :visible.sync='dialogAddVisible' width="50%" 
	:append-to-body="true"
	:close-on-click-modal="false" 
	:modal-append-to-body="false" >
		<el-form  label-position="top"   ref="form" :model="form" prop=""  >	
				<el-row class="field-content">
					<div v-for="(group,index) in groupData"> 
						 <el-col  v-if="group.group_name" :span="23" style="border-bottom:1px solid #E4E7ED;">  
							<p  style="font-weight: bold;font-size: 16px;border-bottom: 3px solid #409EFF;display: inline-block;">{{group.group_name}}</p>
						</el-col> 
						<div v-for="(item,childIndex) in group.data" >
						  <el-col :span="8"  v-if="item.type !== 1 && item.type !== 4"> 
							  <el-form-item  :label="item.title" 
								:required="item.required_status?true:false">
								  	<!-- 普通文本 -->
									<el-input  v-if="item.type < 1" style="width: 280px;" v-model="item.value" 
										:disabled="item.field_name ==='receipt_number' || item.field_name === 'admin_id'?true:false" 
										:placeholder="item.field_name ==='receipt_number'?'编码自动生成':item.title" ></el-input>
									
									<!-- 金额 -->
									 <el-input-number  
									 v-if="item.type == 11" 
									 v-model="item.value" 
									 :precision="2" 
									  :min="0" 
									  :controls="false"
									  style="width:90%"></el-input-number>
									<!-- 下拉框 -->
									<!-- 部门、成员数据下拉 -->
									<template  v-if="item.type == 9">
										<!-- 部门 -->
										<!-- <el-select v-if="item.field_name == 'dept_id'" 
										v-model="item.value"   
										placeholder="请选择"  
										style="width: 280px;"
										@change="handleChangeSelect">
											<el-option v-for="(dept,deptIndex) in deptList"  :key="dept.id" :value="dept.id" :label="dept.name"> </el-option>
											
										</el-select> -->
										<el-cascader
											 v-if="item.field_name == 'dept_id'"
											v-model="item.value"   
											 :props="props"  
											:options="deptList"  
											  @change="handleChangeDept"
											  ref="cascader"  
											></el-cascader> 
										<!-- 部门成员 -->
										<el-select filterable v-else-if="item.field_name == 'user_id'"
										 v-model="item.value"   
										 placeholder="请选择"  
										 style="width: 280px;">
											<!-- <template  v-for="dept in deptList"> -->
												<!-- <el-option
												 v-for="item2 in dept.children"
												 v-if="item2.department == deptId"
												:label="item2.label"
												:value="item2.id">
												</el-option> -->
												<el-option
												 v-for="admin in adminList"
												:label="admin.name"
												:value="admin.id">
												</el-option>
											<!-- </template>			               -->
										</el-select>
										<!-- 调出/调入管理员 -->
										<el-select filterable  v-else-if="item.field_name == 'out_admin_id' 
											|| item.field_name == 'in_admin_id'" v-model="item.value"   placeholder="请选择"  style="width: 280px;">										
												 <el-option
												 v-for="admin in adminList"
												:label="admin.name"
												:value="admin.id">
												</el-option>
										</el-select>
										<!-- 仓库位置 -->
										<!-- <el-form-item v-if="item.field_name == 'location_id'"  prop="location_id" placeholder="请选择"> -->
											<el-select  v-if="selectionStatus 
											&& (item.field_name == 'location_id' 
											|| item.field_name == 'out_location_id' 
											|| item.field_name == 'in_location_id' )"  ref="treeSelect"
												v-model="item.temp_select_name" placeholder="请选择" clearable 
												 @change="(val)=>handleSelect(val,index,childIndex)">
													<el-option value="0" label="无上级">无上级</el-option>
													<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
													 highlight-current ref="tree" 
													 @node-click="(val)=>handleNodeClick(val,index,childIndex)"> </el-tree>
											</el-select>
										<!-- </el-form-item> -->
										<template v-if="
											item.field_name !== 'location_id' 
											&& item.field_name !== 'dept_id' 
											&& item.field_name !== 'user_id'
											&& item.field_name !== 'out_admin_id'
											&& item.field_name !== 'in_admin_id'
											&& item.field_name !== 'out_location_id'
											&& item.field_name !== 'in_location_id'
											">
											<el-select  v-model="item.value"   placeholder="请选择"  style="width: 280px;">
											  <el-option label=""value="0">
											  </el-option>
											</el-select>
										</template>
									</template>
									<!-- 日期选择 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 6"
										v-model="item.value"
										type="date"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 时间日期 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 5"
										v-model="item.value"
										type="datetime"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 金额 -->
									<el-input-number  v-if="item.type == 1" v-model="item.value" :precision="2" :step="0.1" :max="10"></el-input-number>
							  </el-form-item>   
						  </el-col>
						   <el-col :span="24" v-if="item.type == 1 || item.type == 4">
							   <!-- 多行文本 -->
							   <el-form-item v-if="item.type == 1" :label="item.title" 
								:required="item.required_status?true:false">
								<el-input type="textarea" rows="5"  v-model="item.value" placeholder="" ></el-input>
							   </el-form-item> 
							   <el-form-item v-if="item.type == 4" :label="item.title" 
								   :required="item.required_status?true:false">
										<el-upload
										  action="https://jsonplaceholder.typicode.com/posts/"
										  list-type="picture-card"
										 >
										  <i class="el-icon-plus"></i>
										</el-upload>
								</el-form-item> 						
							</el-col>
						  </div>
					</div>
				</el-row>
		</el-form>
		<!-- :row-key="getRowKeys" -->
		 <!-- @selection-change="handleSelectionChange" -->
		 				  <!-- :file-list="fileList" -->
		 <div style="display: flex;margin-bottom: 10px;float: right;"> 
			<template v-if="storageType == 1">
				<!-- <el-upload
				  class="upload-demo"
				  ref="uploadCseFile"
				  :action="uploadUrl"
				  :multiple="false"
				  :on-change="handleChange"
				  :on-success="handleImportSuccess"
				  :auto-upload="true"
				> -->
				<!-- @click="handleBatchExport()" -->
				<el-button type="primary" size="small" plain @click="handleBatchImport"  >批量导入</el-button> 
				</el-upload>
			</template> 
			 <el-button type="primary"  size="small" style="margin-left: 10px;"  @click="handleSelectAsset">添加物料</el-button> 
			 <!-- <p v-if="storageType == 1"> <p class="select-asset" @click="handleExport">批量导入</p></p> -->
			 <!-- <p class="select-asset" @click="handleSelectAsset">添加物料</p> -->
			 
			 <ImportVue ref="batchImportDialog"></ImportVue>
		 </div>
		<el-table
			border
			:data="tableData" 
			:header-cell-style="{height:'35px',padding: '0',fontSize:'12px'}"
			:row-style="{height:'40px'}"
			style="width: 100%;">
			<el-table-column
				width="120"
			    prop="mtl_code"
			    label="物料编码">
			</el-table-column>
			 <el-table-column
			    prop="name"
				width="150"
			    label="物料名称">
			</el-table-column>
			<el-table-column 
			    prop="cate_name"
				width="120"
			    label="物料分类">
			</el-table-column>
			<el-table-column
				width="120"
				prop="unit"
				empty-text="-"
				label="计量单位">
			</el-table-column>
			<el-table-column
				v-if="storageType != 1"
				width="120"
				prop="stock"
				empty-text="-"
				label="可用库存">
			</el-table-column>
			<el-table-column
				width="120"
				prop="price" 
				label="单价"> 
			</el-table-column>
			<!-- 库存调整 -->
			<template v-if="storageType == 6">		
				<el-table-column 
					 prop="stock"
					 width="120"
					 label="调整前数量">
				</el-table-column>
				<el-table-column
					prop="price"
					width="120"
					label="调整前单价">
				</el-table-column>
				<el-table-column
					prop="total_prices"
					width="120"
					label="调整前总价">
				</el-table-column>
				<el-table-column
					width="120"
					 label="">
					 <template slot="header" slot-scope="props">
						<i class="el-icon-edit"></i>调整后数量
					 </template>
					 <template slot-scope="scope">
						<el-input v-if="changeTable" v-model="scope.row.adjust_number" @change="changeAdjustNumber(scope.$index,scope.row.adjust_total_prices)" placeholder=""></el-input>
					 </template>
				</el-table-column>
				<el-table-column
					width="120"
					 prop="adjust_price"
					 label="调整后单价">
					 <template slot-scope="scope">
						 <span v-if="scope.row.adjust_price">{{scope.row.adjust_price}}</span>
						  <span v-else>-</span>
					 </template>
				</el-table-column>
				<el-table-column
					width="120"
					 label="">
					 <template slot="header" slot-scope="props">
						<i class="el-icon-edit"></i>调整后总价
					 </template>
					 <template slot-scope="scope">
						<el-input v-if="changeTable" v-model="scope.row.adjust_total_prices" @change="changeAdjustTotal(scope.$index,scope.row.adjust_number)" placeholder=""></el-input>
					 </template>
				</el-table-column>
			</template>
			<!-- <template v-if="storageType == 1"> -->
				 <el-table-column
					v-if="storageType == 1"
					 prop="count"
					 label="">
					 <template slot="header" slot-scope="props">
						 <i class="el-icon-edit"></i>数量
					 </template>
					 <template slot-scope="scope">
					 	<el-input v-if="changeTable" v-model="scope.row.number" @change="changeNumber(scope.$index,scope.row.price,scope.row.number)" placeholder=""></el-input>
					 </template>
				 </el-table-column>
				 <el-table-column 
					width="120"
					v-if="storageType == 1 || storageType == 5" 
					 prop="total_prices"
					 label="">
					 <template slot="header" slot-scope="props">
					 	<i class="el-icon-edit"></i>
						<span v-if="storageType == 1">总价</span>
						<span v-if="storageType == 5">本次处置金额</span>
					 </template>
					 <template slot-scope="scope">
					 	<el-input v-if="changeTable" 
						v-model="scope.row.total_prices"
						 @input="testInput"
						@change="changeTotal(scope.$index,scope.row.price,scope.row.total_prices)" 
						placeholder=""></el-input>
					 </template>
				 </el-table-column>
				 <el-table-column
				 width="120"
					v-if="storageType == 1"
					 prop="total_prices"
					 label="">
					 <template slot="header" slot-scope="props">
						<i class="el-icon-edit"></i>批次号
					 </template>
					 <template slot-scope="scope">
						<el-input v-if="changeTable" v-model="scope.row.batch_number" placeholder=""></el-input>
					 </template>
				 </el-table-column>
			<!-- </template> -->
			<template v-if="storageType == 2 || storageType ==3 || storageType == 4 || storageType == 5">
				<el-table-column
					width="120"
					 prop="count"
					 label="">
					 <template slot="header" slot-scope="props">
						 <i class="el-icon-edit"></i>
						 <span v-if="storageType == 2">派发数量</span>
						<span v-if="storageType == 3">退库数量</span>
						<span v-if="storageType == 4">调拨数量</span>
						<span v-if="storageType == 5">处置数量</span>
					 </template>
					 <template slot-scope="scope">
						<el-input v-if="changeTable" v-model="scope.row.number" @change="changeDistNumber(scope.$index,scope.row.stock,scope.row.number)" placeholder=""></el-input>
					 </template>
				</el-table-column>
			</template>
		
			<!-- 库存调整不显示以下列 -->
			<template v-if="storageType !== 6">
				<el-table-column
					width="120"
				    prop="barand"
					empty-text='-'
				    label="品牌">
				</el-table-column>
				<el-table-column
					width="120"
				    prop="model"
				    label="规格型号">
				</el-table-column>
			</template>
		
			<el-table-column
				label="操作"
				>
				<template slot-scope="scope">
					 <el-button
						  @click.native.prevent="deleteRow(scope.$index, tableData)"
						  type="text"
						  size="small">
						  移除
					</el-button>
				</template>
			</el-table-column>
		</el-table> 
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogAddVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
		<SelecteConsumableVue ref="selectAsset" :tableData.sync="tableData"></SelecteConsumableVue>
	</el-dialog>
	

</div>
</template>

<script>
import SelecteConsumableVue from '../Form/SelectConsumable.vue'
import ImportVue from '../Consumable/ImportCse.vue'
export default {
	inject:['reload'],
	components:{
	    SelecteConsumableVue,
		ImportVue
	},
    data() {
        return {
			uploadUrl:'/api/admin/CseStorage/import',
			selectionStatus:true,
			dialogVisible:false,
			checkboxGroup:[],
			dialogAddVisible:false,
			cateData:[],
			locationTreeData:[],
			groupData:[], 
			fieldData:[],
			cardName:'',
			cardType:3,
			form:{},
			theadData:[],
			tableData:[],
			deptList:[],
			deptId:'',
			dialogTitle:'',
			addUrl:'',
			editUrl:'',
			treePorps:{
				label:'name'
			},
			storageType:1,	//操作数据类型
			changeTable:true,
			fileList:[],
			total_prices:0,
			adminList:[],		//管理员列表
			location_id:0,
			discoveryId:0,			//新增盘盈时会传递该参数
			mtlCodeData:[],
			staffStorageId:0,
			props:{
				value:'id',
				label:'name',
			},
        }
		
    },
    created() {
		
    },
    mounted() {
		
    },
	// watch: {
	// 	'tableData'() {
	// 		console.log('change-table')
	// 	},
		
	// },
    methods:{
		handleAdd(id=0){ 
			if (id < 1) { 
				// console.log('s_type:',this.storageType,',card_name:',this.cardName) 
				this.$api.get(this.addUrl,null,res=>{
					if(res.code == 200){
						this.dialogTitle =  '添加'+this.cardName
						this.$api.get('AssetForm/getFieldAndTable', {
							name:this.cardName,
							type:this.cardType
						}, res => {
							if (res.code == 200) {
								this.groupData =  res.data.group_data
								this.theadData =  res.data.thead_data
								this.checkboxGroup =  res.data.default_checked
								
								//获取部门
								this.$api.get('Department/deptList',null,deptRes=>{
									if(deptRes.code == 200){
										this.deptList =  deptRes.data.list
									}
								})
								// this.$api.get('admin/deptList',null,res=>{
								//     if(res.code == 200){
								//         // this.adminList  = res.data
								// 		this.deptList =  res.data
								// 	}
								// })
								
								//管理员列表
								// this.$api.post('admin/index',{
								// 	page:0,
								// 	limit:0 
								// },res=>{
								//     if(res.code == 200){
								// 		this.adminList =  res.data.data
								// 	}
								// })
								this.$api.get('admin/getDeptAdmin',null,adminRes=>{
									  if(adminRes.code == 200){  
											this.adminList =  adminRes.data.list
									  }else{
										  this.$message.error(adminRes.msg)
									  } 
								 })
								
								//获取仓库位置
								this.$api.get('AssetLocation/treeData',{type:2},res=>{		
								    if(res.code == 200){
										this.locationTreeData =  res.data.tree_list
									}
								})
								this.dialogAddVisible = true
							}
						})
					}else{
						this.$message.error(res.msg)
					}
				})
				
			} else {
				// this.handleEdit(id)
			}
		},
		testInput(val){
			console.log('------',val)
		},

		//监听部门选择 更新部门成员数据
		handleChangeDept(value){
			let dept_id = value[value.length-1]    
			 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
				  if(res.code == 200){  
						this.adminList =  res.data.list
				  }else{
					  this.$message.error(res.msg)
				  } 
			  })
		},
		//监听总价获取数量
		changeTotal(index,price,total){
			// if(price > 0){	
				let number =  parseInt(total/price)
				this.changeTable = false 
				this.$nextTick(()=>{
					this.changeTable =  true
					this.tableData[index]['number'] = number != number?this.tableData[index]['number']:number
					this.tableData =  JSON.parse(JSON.stringify(this.tableData))
					this.calcAmount()	
				})	
			// }
			
			// console.log('index:',index,',单价：',price,',val:',total,',number:',number)			
		},
		//监听数量获取总价
		changeNumber(index,price,number){
			let total_prices = price > 0? price*number:this.tableData[index]['total_prices']
			this.changeTable = false
			this.$nextTick(()=>{
				this.changeTable =  true
				this.tableData[index]['total_prices'] = total_prices
				this.tableData  = JSON.parse(JSON.stringify(this.tableData))
				this.calcAmount()
			})	
		},
		
		calcAmount(){		//计算操作资产总价
			this.total_prices = 0
			this.tableData.forEach((item)=>{		//合计金额
				 if(item.total_prices){
					 this.total_prices += Math.ceil(item.total_prices * 100) / 100
				 }
			})
			this.groupData.forEach((item,index)=>{
				item.data.forEach((item2,index2)=>{
					if(item2.field_name == 'total_amount'){
						this.groupData[index]['data'][index2]['value'] = Math.ceil(this.total_prices * 100) / 100
					}
				})
			})
			
			console.log('计算总价')
		},
		
		//监听调整总价
		changeAdjustTotal(index,number){
			this.changeTable = false
			console.log('调整：',this.tableData[index]['adjust_total_prices'])
			this.$nextTick(()=>{
				this.changeTable =  true
				if(number > 0){	
					var price = this.tableData[index]['adjust_total_prices'] / number
					this.tableData[index]['adjust_price'] = price
				}
			})			
		},
		//监听调整数量
		changeAdjustNumber(index,total_prices){
			this.changeTable = false
			this.$nextTick(()=>{
				this.changeTable =  true
				if(total_prices){
					this.tableData[index]['adjust_price'] = total_prices / this.tableData[index]['adjust_number']
				}
			})			
		},
		
		
		//监听派发数量
		changeDistNumber(index,stock,number){
			// if(this.tableData[index]['stock']  < )
			
			this.changeTable = false
			if(this.storageType !== 3 && parseInt(stock) < parseInt(number)){
				this.$message.error('数量不能大于可用库存')
				number =  0
				return false
			}
			this.$nextTick(()=>{
				this.changeTable =  true
				this.tableData[index]['number'] = number
			})	
				
			//计算调拨总价和数量 或者 处置的合计金额
			if(this.storageType == 4 || this.storageType == 5){
				this.groupData[0]['data'].forEach((item2,index2)=>{
					let totalPrices = 0.00
					let totalNumber =  0
					this.tableData.forEach((item,index)=>{
						totalNumber  += parseInt(item.number)
						totalPrices += item.number*item.price
						
						if(item2.field_name == 'total_amount'){
							this.groupData[0]['data'][index2]['value'] = totalPrices
						}
						if(item2.field_name == 'total_count'){
							this.groupData[0]['data'][index2]['value'] = totalNumber
						}
					})
				})
			}
		},
		
		//监听仓库位置
		handleSelect(data,index,childIndex){
			// console.log('handle')
		},
		handleNodeClick(data,index,childIndex){
			
			this.selectionStatus =  false
			this.$nextTick(()=>{
				this.selectionStatus =  true
				this.groupData[index]['data'][childIndex]['value'] = data.id
				this.groupData[index]['data'][childIndex]['temp_select_name'] = data.name
				// this.$refs.treeSelect[0].blur()
				// console.log(this.$refs)
				
				if(this.groupData[index]['data'][childIndex]['field_name'] == 'out_location_id' || this.groupData[index]['data'][childIndex]['field_name'] == 'location_id'){		//选择仓库
					this.location_id  = this.groupData[index]['data'][childIndex]['value']
				}
			
			})
		},
		handleSelectAsset(){
			// if(this.storageType == 3){	//退库单 必须先选择退库人
				
			// }
			console.log(this.tableData)
			this.$nextTick(()=>{
				this.$refs.selectAsset.form.type =  this.storageType
				this.$refs.selectAsset.form.mtl_code_data =  this.mtlCodeData
				this.$refs.selectAsset.showAsset(this.tableData,this.location_id)
				
			})
		},
		handleChange(file,fileList){
			this.$emit('handleBeforeUploadFile',file,fileList,true,res=>{
				this.fileList =  res
			})
		},
		// handleImportSuccess(res){
		// 	this.$refs.uploadCseFile.clearFiles()
		//     if(res.code == 200){
		// 		this.$message.success('Success')
		// 		this.tableData =  res.data.table_list
		// 		this.calcAmount()
				
		//      }else{
		//          this.$message.error(res.msg)
		//      }
		// },
		//批量导入弹窗
		handleBatchImport(){
			this.$refs.batchImportDialog.dialogImportVisible  = true
		},
		updateImportTableData(data){
			this.tableData = data
			this.calcAmount()
		},
		deleteRow(index, rows) {
			rows.splice(index, 1);
			this.calcAmount()
			// this.$refs.selectAsset.handleUpdateTableData(rows,this.location_id)
		},
		confirm(){
		   let url = this.addUrl
			if (this.form.id) {
				url = this.editUrl
			}
			this.form.field_data =  this.groupData
			this.form.checked_cse_file = this.tableData
			this.form.type =  this.storageType
			this.form.card_name = this.cardName
			this.form.staff_storage_id = this.staffStorageId
			if(this.discoveryId > 0){
				this.form.discovery_id = this.discoveryId
			}
			this.$api.post(url, this.form, res => {
				if (res.code == 200) {
					this.dialogAddVisible = false
					this.$message.success(res.msg)
					this.reload();
					this.$refs["form"].resetFields();
		  
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//导入物料
		handleExport(){
				
		},
		handleChangeSelect(val){
			this.deptId  = val
		}
    }, 
}
</script>
<style scoped>
	.select-asset{
		color: #409EFF;
		font-weight: 600;
		font-size: 14px;
		text-align: right;
	}
</style>